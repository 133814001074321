@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
	scroll-behavior: smooth;
}
body {
	background: #020522e0;
}
section {
	position: relative;
	padding: 100px;
}
.header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000000;
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
}
.header .logo {
	color: #fff;
	text-decoration: none;
	font-size: 1em;
}
.header .toggle img {
	max-width: 40px;
	cursor: pointer;
}
.banner {
	position: relative;
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.banner:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 400px;
	z-index: 1;
	background: linear-gradient(to top, #111, transparent);
}
.banner .content {
	position: relative;
	max-width: 900px;
	text-align: center;
	z-index: 1;
}
.banner .content h2 {
	color: #fff;
	font-size: 2.5em;
}
.banner .content p {
	color: #fff;
	font-size: 1em;
}
.btn {
	position: relative;
	display: inline-block;
	margin: 30px;
	padding: 10px;
	background-color: #fff;
	color: #333;
	text-decoration: none;
	border-radius: 0.5em;
}
.fitBg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.8;
	border-radius: 0.5em;
}
.about {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.about .contentBx {
	min-width: 50%;
	width: 50%;
	text-align: end;
	padding-right: 40px;
}
.titleText {
	font-weight: 600;
	color: #fff;
	font-size: 2em;
	margin-bottom: 10px;
	margin-top: 10px;
}
.text {
	color: #fff;
	font-size: 1em;
}
.about .imgBx {
	position: relative;
	min-width: 50%;
	width: 50%;
	min-height: 450px;
	border-radius: 1em;
}
.banner2 {
	position: relative;
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.banner3 {
	min-height: 80vh;
}
.banner2:before,
.banner3:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 400px;
	z-index: 1;
	background: linear-gradient(to top, #111, transparent);
}
.banner2:after,
.banner3::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 400px;
	z-index: 1;
	background: linear-gradient(to bottom, #111, transparent);
}
.destination {
	text-align: center;
}
.destination .content {
	text-align: center;
}
.destination .destinationList {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 40px;
}
.destination .destinationList .box {
	position: relative;
	min-width: 350px;
	height: 480px;
	background: #191919;
	transition: 0.5s;
	margin: 20px;
}
.destination .destinationList:hover .box {
	opacity: 0.2;
}
.destination .destinationList .box:hover {
	opacity: 1;
}
.destination .destinationList .box .imgBx {
	position: relative;
	width: 100%;
	height: 400px;
}
.destination .destinationList .box .content {
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.destination .destinationList .box .content h2 {
	color: #fff;
	font-weight: 500;
	line-height: 1.2em;
}
.destination .destinationList .box .content h2 span {
	font-size: 0.8em;
	font-weight: 300;
	opacity: 0.5;
}
.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.footer .sci {
	position: relative;
	display: flex;
}
.footer .sci li {
	list-style: none;
}
.footer .sci li a {
	text-decoration: none;
	margin: 0 20px;
}
.footer .sci li a img {
	filter: invert(1);
	max-width: 40px;
}
.copyrightText {
	margin-top: 20px;
	color: #fff;
	font-size: 18px;
	font-weight: 300;
	color: #666;
}
.copyrightText a {
	color: #fff;
	text-decoration: none;
}
@media (max-width: 600px) {
	section {
		padding: 40px;
	}
	.banner .content h2 {
		font-size: 1.5em;
	}
	.banner .content p {
		font-size: 1em;
	}
	.about {
		flex-direction: column;
	}
	.about .contentBx {
		order: 1;
	}
	.about .contentBx,
	.about .imgBx {
		min-width: 100%;
		width: 100%;
		text-align: center;
		padding-right: 0px;
	}
	.about .imgBx {
		min-height: 250px;
	}
	.btn {
		margin-bottom: 30px;
	}
	.destination .destinationList .box {
		min-width: initial;
		max-width: 350px;
		width: 350px;
		height: 400px;
	}
	.destination .destinationList .box .imgBx {
		height: 320px;
	}
	.footer .sci li a img {
		filter: invert(1);
		max-width: 40px;
	}
	.titleText {
		font-weight: 600;
		color: #fff;
		font-size: 1.5em;
		margin-bottom: 10px;
	}
	.text {
		font-size: 0.8em;
	}
	.about .contentBx {
		text-align: center !important;
		padding-left: 0px !important;
	}
}
